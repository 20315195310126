.active {
  border-bottom: 2px solid #f9a11b;
  transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  
}

img {
  object-fit: cover;
}

.dropdown-translate-button
{
  margin-bottom:0  !important;
}